<template>
  <div>
    <b-container>
      <div
        v-if="loading"
        class="text-primary font-size-22 d-flex justify-content-center align-items-end p-5"
      >
        <spinner-loading text="loading" />
      </div>
      <div v-else>
        <div v-if="status">
          <p
            class="text-primary pt-5 font-size-28 text-center font-size-22 font-weight-bold"
          >
            تم الدفع بنجاح
          </p>
          <div class="d-flex justify-content-center align-items-center">
            <img
              :src="require('@/assets/animation/successPayment.svg')"
              class="w-300px"
            />
          </div>
        </div>
        <div v-else-if="!status">
          <p
            class="text-danger pt-5 font-size-28 text-center font-size-22 font-weight-bold"
          >
            حدث خطأ اثناء الدفع
          </p>
          <div class="d-flex justify-content-center align-items-center">
            <img
              :src="require('@/assets/animation/rejectPayment.svg')"
              class="w-300px"
            />
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
/*eslint-disable */
// import Payment from '@/Utils/payment'
import { core } from "@/config/pluginInit";
import walletService from "@/modules/userProfile/services/userProfile";
export default {
  data() {
    return {
      loading: true,
      status: "",
    };
  },
  methods: {
    async checkStatus() {
      this.loading = true;
      const initiateFromSessionStorage = JSON.parse(
        sessionStorage.getItem("initiateData")
      );
      const transactionId = initiateFromSessionStorage.transaction_id;
      const checkoutId = initiateFromSessionStorage.checkout_id;

      try {
        const res = await walletService.checkPaymentStatus({
          checkout_id: checkoutId,
          transaction_id: transactionId,
        });

        this.status = res.data.status;
        console.log("Payment status:", this.status);

        if (this.status) {
          // Only navigate if the payment is successful
          setTimeout(() => {
            const lastRoute = localStorage.getItem("lastRoute");
            console.log("Last route is:", lastRoute); // Check the value
            if (lastRoute === "veterinarian") {
              this.$router.push("/user-profile/user-veterinary");
            } else if (lastRoute === "transportation") {
              this.$router.push("/user-profile/transportation-profile");
            } else if (lastRoute === "insurance") {
              this.$router.push("/user-profile/insurance-profile");
            } else if (lastRoute === "cart") {
              this.$router.push("/user-profile/user-marketplace");
            }
          }, 2000);
        } else {
          console.error("Payment failed.");
        }
      } catch (error) {
        console.error("Error checking payment status:", error);
        this.status = false;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    core.index();
  },
  created() {
    this.checkStatus();
  },
};
</script>
<style></style>
